*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: SuisseIntl;
  src: url(./assets/SuisseIntl-Book.otf) format(opentype);
  font-weight: 401;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: SuisseIntl;
  src: url(./assets/SuisseIntl-Bold.otf) format(opentype);
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: SuisseIntl;
  src: url(./assets/SuisseIntl-Thin.otf) format(opentype);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

html,
body {
  /* background: #f3f3f4; */
  background: #e5e5e5;
  color: #afbdd1;
  font-size: 14px;
  font-family: "SuisseIntl", -apple-system, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

#walktour-tooltip-container {
  outline: none;
}

/* #root,
#root > *:not(.MuiSnackbar-root) {
    min-height: 100vh;
} */

a,
a:visited {
  cursor: pointer;
  color: #34d7e2;
  text-decoration: none;
}

a:hover {
  color: #0eefff;
}

#okta-sign-in.auth-container.main-container {
  background-color: #fff;
}

#okta-sign-in.auth-container .button-primary {
  background: #fa49a0;
  border-color: #fa49a0;
  color: #263238;
  font-weight: 600;
}

#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:hover {
  border-color: #fa49a0;
  background: #fa49a0;
  /*opacity: .7;*/
  /*cursor: not-allowed;*/
}

.loader {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.image-layout {
  position: relative;
  width: calc(100% + 46px);
  height: calc(100vh - 70px);
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  margin: 0 -23px;
}

.image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}

.spinner {
  position: absolute;
  width: 230px;
  height: 230px;
}

@keyframes spinner2 {
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
}

.spinner-item {
  position: absolute;

  border: 4px solid transparent;

  border-radius: 50%;
  animation: spinner2 700ms linear infinite;
}

.spinner-item:nth-of-type(1) {
  left: calc(50% - 230px / 2);
  top: calc(50% - 230px / 2);
  width: 230px;
  height: 230px;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
  border-top: 4px solid #000;
  animation: spinner2 600ms linear infinite;
}

.spinner-item:nth-of-type(2) {
  left: calc(50% - 210px / 2);
  top: calc(50% - 210px / 2);
  width: 210px;
  height: 210px;
  /*border-left: 4px solid #FA49A0;*/
  border-right: 4px solid #ccc;
  border-bottom: 4px solid #ccc;
  animation: spinner2 650ms linear infinite;
}

.spinner-item:nth-of-type(3) {
  left: calc(50% - 190px / 2);
  top: calc(50% - 190px / 2);
  width: 190px;
  height: 190px;
  border-left: 4px solid #fa49a0;
  /*border-right: 4px solid red;*/
  border-bottom: 4px solid #fa49a0;
  animation: spinner2 700ms linear infinite;
}

.image-container img {
  width: 100%;
  height: auto;
}

.line-scale {
  display: flex;
}

.line-scale>div {
  background-color: #263238;
  width: 2px;
  height: 20px;
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale>div:nth-child(1) {
  -webkit-animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale>div:nth-child(2) {
  -webkit-animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale>div:nth-child(3) {
  -webkit-animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale>div:nth-child(4) {
  -webkit-animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale>div:nth-child(5) {
  -webkit-animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

#okta-sign-in.auth-container .okta-form-label {
  color: #263238;
}

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  color: #263238;
  font-size: 23px;
}

#okta-sign-in.auth-container.main-container {
  color: #263238;
}

/*#263238*/
#okta-sign-in.auth-container .link,
#okta-sign-in.auth-container .link:active,
#okta-sign-in.auth-container .link:hover,
#okta-sign-in.auth-container .link:link,
#okta-sign-in.auth-container .link:visited {
  color: #263238;
}

#okta-sign-in.auth-container.main-container {
  border-radius: 20px;
}

#okta-sign-in.auth-container input[type="button"],
#okta-sign-in.auth-container input[type="submit"] {}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus {
  box-shadow: 0 0 8px #fa49a0;
}

#okta-sign-in.auth-container.main-container {
  border-color: #263238;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border-color: #263238;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  background: #fa49a0;
  border-color: #fa49a0;
  opacity: 0.7;
  color: #263238;
}

.label-custom {
  display: inline-flex;
  align-items: center;
}

.icon-margin {
  margin-right: 2px;
}

.icon-yellow {
  background: #fa49a0;
}

#okta-sign-in.auth-container.main-container {
  border-color: transparent;
  box-shadow: 5px 5px 10px #ccc;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border-color: #ccc;
}

#okta-sign-in .registration-container .registration-link {
  margin-top: 15px;
  display: block;
  text-align: center;
  font-weight: bold;
  line-height: 50px;
  width: 100%;
  height: 50px;
  background: #fa49a0;
  color: #263238;
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.table-grey__row {
  /*background-color: rgba(0, 0, 0, 0.04);*/
  opacity: 0.5;
  cursor: not-allowed;
}

.link__yallow_dark {
  color: #fa49a0;
}

.link__pink_dark {
  color: #fa49a0;
}

.ag-theme-custom {
  line-height: 20px !important;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  font-size: 0.875rem;
}

.ag-theme-alpine .ag-row {
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.ag-theme-alpine .ag-row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.ag-theme-alpine .ag-header {
  background-color: #fff !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #fff !important;
}

.ag-theme-alpine .ag-row-odd:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/*.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {*/
/*    text-transform: capitalize;*/
/*}*/

.new-table.ag-theme-alpine .ag-header-row {
  color: rgba(0, 0, 0, 0.6);
}

.txt-capitalize:first-letter {
  text-transform: capitalize;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 1.5;
}

.text-cell {
  justify-content: left;
}

.checkbox-table.ag-theme-alpine .ag-cell {
  line-height: 20px;
  font-size: 12px;
}

.ag-theme-alpine.checkbox-table .ag-checkbox-input-wrapper.ag-checked::after {
  color: #000;
}

.ag-theme-alpine.checkbox-table .ag-row:hover {
  background-color: rgba(252, 215, 54, 0.1);
}

.ag-theme-alpine.checkbox-table .ag-row-odd:hover {
  background-color: rgba(252, 215, 54, 0.1) !important;
}

.ag-theme-alpine.checkbox-table .ag-row-selected {
  background-color: rgba(252, 215, 54, 0.1) !important;
}

.ag-theme-alpine.checkbox-table .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: transparent;
}

.ag-theme-alpine .ag-row-selected {
  background-color: inherit !important;
}

.checkbox-table .ag-header-cell-label {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 12px;
}

.ag-theme-alpine.checkbox-table .ag-ltr .ag-cell {
  border-bottom: 6px solid #fff;
  border-top: 6px solid #fff;
}

.checkbox-table-web .ag-header {
  display: none;
}

.checkbox-table-web .ag-selection-checkbox {
  margin-left: 26px;
}

.checkbox-table-web .ag-cell-wrapper,
.checkbox-table-web .ag-cell-value {
  width: 100%;
}

.link_yellow {
  color: #d5ad00;
  text-decoration: underline;
}

.link_yellow:hover {
  color: #d5ad00;
  text-decoration: none;
}

.link_yellow:visited {
  color: #d5ad00;
}

.link_copy {
  color: #000;
  text-decoration: underline;
  margin-left: 8px;
}

.link_copy:hover {
  text-decoration: none;
  color: inherit;
}

.img-responsive {
  width: 100%;
  height: auto;
}

.without-break .ag-cell-wrap-text {
  word-break: normal;
}

.code-block {
  margin: 10px 0;
  display: inline-block;
  border-radius: 5px;
  background: #cfcfcf;
  padding: 10px 20px;
}
