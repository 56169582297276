.box-view-loading {
  position: relative;
}

.box-view-loading:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-view-loading .box-view-loading__loader {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 15px);
}
